import React, { useEffect, useRef, useState } from "react";
// import Isotope from "isotope-layout";
import { randomProject, projectsData } from "../documents/data";
import ProjectDetailsModal from "./ProjectDetailsModal";
import TestModal from "./TestModal";
import axios from "axios";
import "../assets/app.css";

const ProjectTest = () => {
  const [articles, setArticles] = useState([]);
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    const username = "ethanlloyd21";

    const url = `https://dev.to/api/articles?username=${username}`;

    const getData = async () => {
      await axios
        .get(url)
        // .then(response => response.json())
        .then((data) => {
          setArticles(data.data);
          setLoading(true);
          console.log(data.data);
        })
        .catch((error) => {
          console.error(error);
        });
    };
    getData();
  }, []);

  // init one ref to store the future isotope object
  const isotope = useRef();

  // store the filter keyword in a state
  const [filterKey, setFilterKey] = useState("DETAILED");
  const [imagesLoaded, setimagesLoaded] = useState(0);
  const [selectedProjectDetails, setSelectedProjectDetails] = useState();
  const [isOpen, setIsOpen] = useState(false);

  const htmlElement = document.getElementsByTagName("html")[0];
  const isRtl = htmlElement.getAttribute("dir") === "rtl";

  const filters = {
    MOCKUPS: "Blogs",
    DETAILED: "Enterprise",
    VIMEO: "Personal",
    YOUTUBE: "Youtube Videos",
  };

  const types = {
    IMAGE: "image",
    VIDEO: "video",
    DOCUMENT: "document",
  };

  // const projectsData = [
  //   {
  //     title: "Donationally - Charity Website",
  //     type: types.DOCUMENT,
  //     document: {
  //       projectInfo:
  //         "Donationally is a charity search navigator, helping users in multiple ways to stay informed about world events and opportunities to give back to those in need. Our mission is to create lasting change one donation at a time",
  //       client: "Freelance",
  //       technologies:
  //         "HTML/CSS, Javascript, React, React Hooks, React-Bootstrap, Ant Design, Formik, Yup, axios, Google API, Express, MongoDB, Mongoose, Node.js",
  //       industry: "Website",
  //       date: "April 25, 2020",
  //       url: {
  //         name: "www.donationally.com",
  //         link: "https://www.donationally.com",
  //       },

  //       sliderImages: "",
  //     },

  //     thumbImage: "images/projects/project-1.jpg",

  //     categories: [filters.DETAILED],
  //   },
  //   {
  //     title: "Why I love using Amplify",
  //     type: types.IMAGE,

  //     thumbImage: "/images/projects/amplify.jpg",

  //     categories: [filters.MOCKUPS],
  //   },
  //   {
  //     title: "YouTube Video",
  //     type: types.VIDEO,
  //     video: {
  //       vimeo: false,
  //       id: "PMNnEEEacCg",
  //     },
  //     thumbImage: "images/projects/project-3.jpg",

  //     categories: [filters.YOUTUBE],
  //   },
  //   {
  //     title: "Vimeo Video",
  //     type: types.VIDEO,
  //     video: {
  //       vimeo: true,
  //       id: "259411563",
  //     },

  //     thumbImage: "images/projects/project-4.jpg",
  //     categories: [filters.VIMEO],
  //   },
  //   {
  //     title: "OLIVIA",
  //     type: types.DOCUMENT,
  //     document: {
  //       projectInfo:
  //         "OLIVIA is a React application designed to support the Global Security Operations unique data management needs. Its features include distribution list management, corporate building information, contact information, disaster risk intelligence platform and language translation.",
  //       client: "Amazon",
  //       technologies:
  //         "React, Ant Design, React Bootstrap, Node.js, AWS Amplify, Cognito, API Gateway, Lambda, Cloud Front, Cloud Watch, Athena, Amazon Translate, DynamoDB, S3, Leaflet map, USGS API, Disaster Aware API, Serveless application",
  //       industry: "In house technology application",
  //       date: "January 20, 2021",
  //       url: {
  //         name: "restricted",
  //         link: "",
  //       },
  //       sliderImages: "",
  //     },
  //     thumbImage: "images/projects/project-5.jpg",
  //     categories: [filters.DETAILED],
  //   },
  //   {
  //     title: "ROXANNE",
  //     type: types.DOCUMENT,
  //     document: {
  //       projectInfo:
  //         "ROXANNE is a java based application that Global Security Operations Operator use to manage daily workloads while boosting the team's productivity",
  //       client: "Amazon",
  //       technologies: "Java, Java Swing",
  //       industry: "In house technology application",
  //       date: "Febuary 14, 2017",
  //       url: {
  //         name: "restricted",
  //         link: "",
  //       },
  //       sliderImages: "",
  //     },
  //     thumbImage: "images/projects/project-5.jpg",
  //     categories: [filters.DETAILED],
  //   },
  //   {
  //     title: "React Typescript + Vite + AWS Amplify",
  //     type: types.IMAGE,

  //     thumbImage: "images/projects/vite_amplify.png",

  //     categories: [filters.MOCKUPS],
  //   },
  // ];

  // initialize an Isotope object with configs
  //   useEffect(() => {
  //     isotope.current = new Isotope(".portfolio-filter", {
  //       itemSelector: ".filter-item",
  //       layoutMode: "masonry",
  //       originLeft: !isRtl,
  //     });

  //     // cleanup
  //     return () => {
  //       isotope.current.destroy();
  //     };
  //   }, []);

  //   // handling filter key change
  //   useEffect(() => {
  //     if (imagesLoaded) {
  //       filterKey === "*"
  //         ? isotope.current.arrange({ filter: `*` })
  //         : isotope.current.arrange({ filter: `.${filterKey}` });
  //     }
  //   }, [filterKey, imagesLoaded]);

  const handleFilterKeyChange = (key) => () => setFilterKey(key);

  const getKeyByValue = (value) => {
    return Object.keys(filters).find((key) => filters[key] === value);
  };

  const getFilterClasses = (categories) => {
    if (categories.length > 0) {
      let tempArray = [];
      categories.forEach((category, index) => {
        tempArray.push(getKeyByValue(category));
      });
      return tempArray.join(" ");
    }
  };

  //open a new window for the blog
  const openInNewTab = (url) => {
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };

  function truncateDescription(description) {
    const words = description.split(" ");
    if (words.length > 15) {
      return words.slice(0, 15).join(" ") + "..."; // The '...' indicates there's more content
    }
    return description;
  }

  return (
    <>
      <section id="portfolio" className={"section bg-light"}>
        <div className={"container"}>
          {/* Heading */}
          <p className="text-center mb-2 wow fadeInUp">
            <span className="bg-primary text-dark px-2">Portfolio</span>
          </p>
          <h2 className="text-10 fw-600 text-center mb-5 wow fadeInUp">
            Project Collections
          </h2>
          {/* Heading end*/}
          {/* Filter Menu */}
          <ul
            className={
              "portfolio-menu nav nav-tabs fw-600 justify-content-start justify-content-md-center border-bottom-0 mb-5 wow fadeInUp"
            }
          >
            {/* <li className="nav-item">
              <button
                className={"nav-link " + (filterKey === "DETAILED" ? "active" : "")}
                onClick={handleFilterKeyChange("*")}
              >
                Enterprises
              </button>
            </li> */}
            {Object.keys(filters).map((oneKey, i) => (
              <li className="nav-item" key={i}>
                <button
                  className={
                    "nav-link " + (filterKey === oneKey ? "active" : "")
                  }
                  onClick={handleFilterKeyChange(oneKey)}
                >
                  {filters[oneKey]}
                </button>
              </li>
            ))}
          </ul>
          {/* Filter Menu end */}

          <div className="portfolio wow fadeInUp">
            <div className="row portfolio-filter filter-container g-4">
              {/**Youtube */}
              {filterKey === "YOUTUBE" ? (
                <p className="text-center wow fadeInUp">
                  - Please note that this section is currently under
                  construction and will be updated soon. -
                </p>
              ) : null}

              {/**Blogs */}
              {filterKey === "MOCKUPS" &&
                articles.length > 0 &&
                articles.map((project, index) => (
                  <div
                    className={
                      "col-sm-6 col-lg-4 filter-item " +
                      getFilterClasses([filters.MOCKUPS])
                    }
                    key={index}
                  >
                    <div
                      className="card"
                      id="projects-cards"
                      style={{ cursor: "pointer" }}
                    >
                      <img
                        onLoad={() => {
                          setimagesLoaded(imagesLoaded + 1);
                        }}
                        src={project.cover_image}
                        className="card-img-top"
                        alt="..."
                        width={500}
                        height={180}
                        onClick={() => {
                          setSelectedProjectDetails(project);
                          openInNewTab(project.canonical_url);
                        }}
                      />

                      <div class="card-body">
                        <h5 class="card-title">{project.title}</h5>
                        {/* <p class="card-text">
                          {truncateDescription(project.description)}
                        </p> */}
                      </div>
                    </div>
                    {/* <div className="portfolio-box">
                      <div className="portfolio-img">
                        <img
                          onLoad={() => {
                            setimagesLoaded(imagesLoaded + 1);
                          }}
                          className="img-fluid d-block portfolio-image"
                          src={project.cover_image}
                          alt=""
                        />
                        <div
                          className="portfolio-overlay"
                          onClick={() => {
                            openInNewTab(project.url);
                            // setSelectedProjectDetails(project);
                          }}
                        >
                          <button className="popup-ajax stretched-link border-0 p-0 ">
                            {" "}
                          </button>
                          <div className="portfolio-overlay-details">
                            <p className="text-primary text-8">
                              {project.type === types.DOCUMENT && (
                                <i className="fas fa-file-alt"></i>
                              )}
                              {project.type === types.IMAGE && (
                                <i className="fas fa-image"></i>
                              )}
                              {project.type === types.VIDEO && (
                                <i className="fas fa-video"></i>
                              )}
                            </p>
                            <h5 className="text-white text-5">
                              {project?.title}
                            </h5>
                            <span className="text-light">Category</span>
                          </div>
                        </div>
                      </div>
                    </div> */}
                  </div>
                ))}
              {/**Projects */}
              {filterKey === "DETAILED" &&
                projectsData.length > 0 &&
                projectsData.map((project, index) => (
                  <div
                    className={
                      "col-sm-6 col-lg-4 filter-item " +
                      getFilterClasses([filters.DETAILED])
                    }
                    key={index}
                  >
                    {/* <div className="portfolio-box">
                      <div className="portfolio-img">
                        <img
                          onLoad={() => {
                            setimagesLoaded(imagesLoaded + 1);
                          }}
                          className="img-fluid d-block portfolio-image"
                          src={project.thumbImage}
                          alt=""
                          width={600}
                        />
                        <div
                          className="portfolio-overlay"
                          onClick={() => {
                            setSelectedProjectDetails(project);
                            setIsOpen(true);
                          }}
                        >
                          <button className="popup-ajax stretched-link border-0 p-0 ">
                            {" "}
                          </button>
                          <div className="portfolio-overlay-details">
                            <p className="text-primary text-8">
                              {project.type === types.DOCUMENT && (
                                <i className="fas fa-file-alt"></i>
                              )}
                              {project.type === types.IMAGE && (
                                <i className="fas fa-image"></i>
                              )}
                              {project.type === types.VIDEO && (
                                <i className="fas fa-video"></i>
                              )}
                            </p>
                            <h5 className="text-white text-5">
                              {project?.title}
                            </h5>
                            <span className="text-light">Category</span>
                          </div>
                        </div>
                      </div>
                    </div> */}
                    <div
                      className="card"
                      id="projects-cards"
                      style={{ cursor: "pointer" }}
                    >
                      <img
                        onLoad={() => {
                          setimagesLoaded(imagesLoaded + 1);
                        }}
                        src={project.thumbImage}
                        className="card-img-top"
                        alt="..."
                        width={500}
                        height={250}
                        onClick={() => {
                          setSelectedProjectDetails(project);
                          setIsOpen(true);
                        }}
                      />

                      <div class="card-body">
                        <h5 class="card-title">{project.title}</h5>
                        <p class="card-text">
                          {truncateDescription(project.description)}
                        </p>
                      </div>
                    </div>
                  </div>
                ))}

              {/**Random Projets */}
              {filterKey === "VIMEO" &&
                randomProject.length > 0 &&
                randomProject.map((project, index) => (
                  <div
                    className={
                      "col-sm-6 col-lg-4 filter-item " +
                      getFilterClasses([filters.DETAILED])
                    }
                    key={index}
                  >
                    <div
                      className="card"
                      id="projects-cards"
                      style={{ cursor: "pointer" }}
                    >
                      <img
                        onLoad={() => {
                          setimagesLoaded(imagesLoaded + 1);
                        }}
                        src={project.thumbImage}
                        className="card-img-top"
                        alt="..."
                        width={500}
                        height={250}
                        onClick={() => {
                          setSelectedProjectDetails(project);
                          setIsOpen(true);
                        }}
                      />

                      <div class="card-body">
                        <h5 class="card-title">{project.title}</h5>
                        <p class="card-text">
                          {truncateDescription(project.description)}
                        </p>
                      </div>
                    </div>

                    {/* <div className="portfolio-box">
                      <div className="portfolio-img">
                        <img
                          onLoad={() => {
                            setimagesLoaded(imagesLoaded + 1);
                          }}
                          className="img-fluid d-block portfolio-image"
                          src={project.thumbImage}
                          alt=""
                        />
                        <div
                          className="portfolio-overlay"
                          onClick={() => {
                            setSelectedProjectDetails(project);
                            setIsOpen(true);
                          }}
                        >
                          <button className="popup-ajax stretched-link border-0 p-0 ">
                            {" "}
                          </button>
                          <div className="portfolio-overlay-details">
                            <p className="text-primary text-8">
                              {project.type === types.DOCUMENT && (
                                <i className="fas fa-file-alt"></i>
                              )}
                              {project.type === types.IMAGE && (
                                <i className="fas fa-image"></i>
                              )}
                              {project.type === types.VIDEO && (
                                <i className="fas fa-video"></i>
                              )}
                            </p>
                            <h5 className="text-white text-5">
                              {project?.title}
                            </h5>
                            <span className="text-light">Category</span>
                          </div>
                        </div>
                      </div>
                    </div> */}
                  </div>
                ))}
            </div>
          </div>

          {/* <div className="portfolio wow fadeInUp">
              <div className="row portfolio-filter filter-container g-4">
                {projectsData.length > 0 &&
                  projectsData.map((project, index) => (
                    <div
                      className={
                        "col-sm-6 col-lg-4 filter-item " +
                        getFilterClasses(project.categories)
                      }
                      key={index}
                    >
                      <div className="portfolio-box">
                        <div className="portfolio-img">
                          <img
                            onLoad={() => {
                              setimagesLoaded(imagesLoaded + 1);
                            }}
                            className="img-fluid d-block portfolio-image"
                            src={project.thumbImage}
                            alt=""
                          />
                          <div
                            className="portfolio-overlay"
                            onClick={() => {
                              setSelectedProjectDetails(projectsData[index]);
                              setIsOpen(true);
                            }}
                          >
                            <button className="popup-ajax stretched-link border-0 p-0 ">
                              {" "}
                            </button>
                            <div className="portfolio-overlay-details">
                              <p className="text-primary text-8">
                                {project.type === types.DOCUMENT && (
                                  <i className="fas fa-file-alt"></i>
                                )}
                                {project.type === types.IMAGE && (
                                  <i className="fas fa-image"></i>
                                )}
                                {project.type === types.VIDEO && (
                                  <i className="fas fa-video"></i>
                                )}
                              </p>
                              <h5 className="text-white text-5">
                                {project?.title}
                              </h5>
                              <span className="text-light">Category</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </div> */}
        </div>
      </section>

      {/* Modal */}
      {isOpen && (
        <TestModal
          projectDetails={selectedProjectDetails}
          setIsOpen={setIsOpen}
        ></TestModal>
      )}
    </>
  );
};

export default ProjectTest;
