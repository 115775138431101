import React from "react";
const AboutUs = () => {
  return (
    <section id="about" className="section">
      <div className="container">
        {/* Heading */}
        <p className="text-center mb-2 wow fadeInUp">
          <span className="bg-primary text-dark px-2">About Me</span>
        </p>
        <h2 className="text-10 fw-600 text-center mb-5 wow fadeInUp">
          Get to know me better
        </h2>
        {/* Heading end*/}
        <div className="row">
          <div className="col-lg-8 text-center text-lg-start wow fadeInUp">
            <h2 className="text-8 fw-400 mb-3">
              Hi, I'm{" "}
              <span className="fw-700 border-bottom border-3 border-primary">
                Lloyd Marcelino
              </span>
            </h2>
            <p className="text-5">
              {/* I am a Full-Stack Engineer from San Diego, CA who loves to learn
              and creatively solve problems. I specialize in buiding serverless
              applications using AWS secure reliable network. I design and
              develop experiences that makes people's lives simple. */}
              {/* I'm a designer &amp; developer with a passion for web design. I
              enjoy developing simple, clean and slick websites that provide
              real value to the end user. Thousands of clients have procured
              exceptional results while working with me. Delivering work within
              time and budget which meets client’s requirements is our moto. */}
              A Full-Stack Engineer based in Dallas, Texas. My expertise lies in
              building serverless applications using the secure and reliable
              network of Amazon Web Services (AWS), allowing me to create
              efficient, scalable solutions for businesses across various
              sectors. With a comprehensive understanding of both front-end and
              back-end technologies, I am committed to crafting seamless user
              experiences and delivering innovative applications that meet the
              demands of the modern digital landscape.
            </p>
            <p className="text-5">
              {/* I am an easygoing and mild mannered guy who enjoys many hobbies.
              Of these hobbies, I enjoy traveling around the world, experiencing
              different cultures, visiting the beach, composing music and
              playing video games. I am a big fan of San Diego Padres and Los
              Angeles Chargers. */}
              Beyond my technical prowess, I am an easygoing and mild-mannered
              individual with a diverse array of hobbies. My passion for travel
              has taken me around the world, where I have had the pleasure of
              experiencing different cultures and expanding my horizons. Closer
              to home, I enjoy relaxing beach visits, composing music, and
              getting lost in the exciting realm of video games. My enthusiasm
              for sports is showcased by my unwavering support for the San Diego
              Padres and Los Angeles Chargers.
            </p>
            <p className="text-5">
              I am dedicated to building top-notch serverless applications using
              AWS while maintaining a well-balanced and approachable
              personality. With my unique combination of technical skills and
              varied interests, I eagerly anticipate connecting with others,
              forging meaningful relationships, and pushing the boundaries of
              innovation in the world of technology.
            </p>
          </div>
          <div
            className="col-lg-4 mt-4 mt-lg-0 wow fadeInUp"
            data-wow-delay="0.2s"
          >
            <div
              className="border-bottom border-3 border-primary"
              style={{ paddingBottom: 20 }}
            >
              <div className="featured-box style-4">
                <div className="featured-box-icon text-20 fw-500 bg-primary rounded-circle">
                  <span className="wow heartBeat" data-wow-delay="1.3s">
                    8
                  </span>
                </div>
                <h3 className="text-7 wow rubberBand" data-wow-delay="2s">
                  Years of <span className="fw-700">Technical Experience</span>
                </h3>
              </div>
            </div>
            <div className="featured-box style-4" style={{ paddingTop: 20 }}>
              <div className="featured-box-icon text-20 fw-500 bg-primary rounded-circle">
                <span className="wow heartBeat" data-wow-delay="1.3s">
                  5
                </span>
              </div>
              <h3 className="text-7 wow rubberBand" data-wow-delay="2s">
                Years of <span className="fw-700">Dev Experience</span>
              </h3>
            </div>
          </div>
        </div>
        <div className="row gy-3 mt-4">
          <div className="col-6 col-lg-3 wow fadeInUp">
            <p className="text-muted fw-500 mb-0">Name:</p>
            <p className="text-4 text-dark fw-600 mb-0">Lloyd Marcelino</p>
          </div>
          <div className="col-6 col-lg-3 wow fadeInUp" data-wow-delay="0.2s">
            {/* <p className="text-muted fw-500 mb-0">Email:</p>
            <p className="text-4 fw-600 mb-0">
              <a className="link-dark" href="mailto:lloydmarcelino@gmail.com">
                lloydmarcelino@gmail.com
              </a>
            </p> */}
          </div>
          {/* <div className="col-6 col-lg-3 wow fadeInUp" data-wow-delay="0.3s">
            <p className="text-muted fw-500 mb-0">Date of birth:</p>
            <p className="text-4 text-dark fw-600 mb-0">11 November, 1987</p>
          </div> */}
          <div className="col-6 col-lg-3 wow fadeInUp" data-wow-delay="0.4s">
            <p className="text-muted fw-500 mb-0">From:</p>
            <p className="text-4 text-dark fw-600 mb-0">Dallas, Texas</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutUs;
