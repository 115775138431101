import React from "react";

const Services = () => {
  // services details
  const services = [
    {
      name: "Cost",
      desc: "Can help save on infrastructure costs by leveraging pay-per-use serverless platforms, eliminating the need to manage and maintain servers.",
      icon: "fas fa-dollar-sign",
    },
    {
      name: "Scalability",
      desc: "Can build applications that automatically scale with user demand, ensuring a smooth experience during traffic fluctuations without manual intervention.",
      icon: "fas fa-arrows-alt",
    },
    {
      name: "Performance",
      desc: "Can optimize application performance, resulting in faster load times and better user experience.",
      icon: "fa fa-chart-line",
    },
    {
      name: "Maintenance",
      desc: "Can help automate infrastructure management tasks, reducing the burden on in-house teams and freeing up resources to focus on core business functions.",
      icon: "fas fa-cogs",
    },
    {
      name: "Availability",
      desc: "Can design and deploy fault-tolerant systems that minimize downtime and maximize reliability, keeping critical applications online and available.",
      icon: "fas fa-desktop",
    },
    {
      name: "Security",
      desc: "Familiar with security best practices and can implement them to safeguard company data, ensuring compliance with industry standards and regulations.",
      icon: "fas fa-lock",
    },
  ];

  return (
    <section id="services" className="section bg-light">
      <div className="container">
        {/* Heading */}
        <p className=" text-center mb-2 wow fadeInUp">
          <span className="bg-primary text-dark px-2">What I Do?</span>
        </p>
        <h2 className="text-10 fw-600 text-center mb-5 wow fadeInUp">
          How I can help your next project
        </h2>
        {/* Heading end*/}
        <div className="row gy-5 mt-5">
          {services.length > 0 &&
            services.map((service, index) => (
              <div className="col-sm-6 col-lg-4 wow fadeInUp" key={index}>
                <div className="featured-box text-center px-md-4">
                  <div className="featured-box-icon text-primary text-13">
                    {" "}
                    <i className={service.icon} />
                  </div>
                  <h3 className="text-6 fw-600 mb-3">{service.name}</h3>
                  <p className="text-muted mb-0">{service.desc} </p>
                </div>
              </div>
            ))}
        </div>
      </div>
    </section>
  );
};

export default Services;
