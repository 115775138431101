import Sample from "../documents/githubImage/sample.PNG";
import Original from "../documents/githubImage/Original.png";
import budget from "../documents/githubImage/budget.PNG";
import dashboard from "../documents/githubImage/dashboard.PNG";
import resistance from "../documents/githubImage/resistance.PNG";
import google from "../documents/githubImage/google.PNG";
import quiz from "../documents/githubImage/quiz.PNG";
import roxanne from "../documents/githubImage/roxanneImage.jpg";
import weather2 from "../documents/githubImage/weather2.PNG";
import screen from "../documents/githubImage/screen.PNG";
import website from "../documents/githubImage/portfolio.png";
import restaurant from "../documents/githubImage/restaurant.png";
import paypal from "../documents/githubImage/paypal.png";
import login from "../documents/githubImage/login.PNG";
import generator from "../documents/githubImage/generator.PNG";
import gen from "../documents/githubImage/gen.PNG";
import employee from "../documents/githubImage/employee.png";
import auth from "../documents/githubImage/auth.PNG";
// import olivia from "../documents/githubImage/olivia.jpg";
import olivia from '../documents/githubImage/maps.PNG';
import shop from "../documents/githubImage/shop.jpg";
import estate from "../documents/githubImage/estate.png";

export const randomProject = [
  {
    id: 0,
    title: "Employee Directory using React",
    thumbImage: Sample,
    link: "https://ethanlloyd21.github.io/React-Employee-Directory/",
    codeLink: "https://github.com/Ethanlloyd21/React-Employee-Directory",
    description:
      "This application leverages the power of React technology to facilitate an efficient and interactive interface for users to manage their employee database. It allows users to swiftly view and organize their employees based on birthdates, providing a streamlined mechanism for recognizing important personal milestones. Additionally, the application integrates a search function, offering users the convenience of locating specific employees by their names, thereby enhancing overall user experience and operational efficiency.",
    technology: ["Bootstrap", "Node.js", "React", "API", "Webpack"],
    deploy: ["git", "GitHub", "mLab", "Heroku"],
    client: "personal initiative",
    type: "document",
  },
  {
    id: 12,
    title: "MySQL Employee Tracker",
    thumbImage: screen,
    link: "https://drive.google.com/file/d/1YR7YOwPlGoaFdIdoFeKc9-ysnnY5sM2P/view",
    codeLink: "https://github.com/Ethanlloyd21/MySQL-Employee-Tracker",
    description:
      "The MySQL Employee-Tracker serves as an intuitive command-line interface designed to facilitate non-technical users in accessing and manipulating information housed within databases. This interface exemplifies a robust Content Management System (CMS), specifically engineered to streamline the management of a company's workforce utilizing technologies like Node.js, Inquirer, and MySQL. Furthermore, this application showcases proficient utilization of MySQL for data manipulation, with a keen focus on managing tables and columns, thereby underlining its ability to cater to complex data handling requirements.",
    technology: [
      "JavaScript",
      "jQuery",
      "Node.js",
      "ES6+",
      "MySQL",
      "MySQL Workbench",
    ],
    deploy: ["GitHub", "git", "command-line"],
    client: "personal initiative",
    type: "document",
  },
  {
    id: 2,
    title: "Budget Tracker using PWA",
    thumbImage: budget,
    link: "not available",
    codeLink: "https://github.com/Ethanlloyd21/Budget-Tracker",
    description:
      "This application, leveraging the capabilities of Progressive Web Applications (PWA), provides users with the flexibility to record both expenses and deposits in their budget irrespective of their connectivity status. It has been designed to accommodate offline transactions, which will subsequently be synchronized and reflected in the total balance upon the restoration of an internet connection. This seamless integration of offline and online functionalities ensures that users maintain an accurate and up-to-date overview of their financial transactions at all times.",
    technology: [
      "HTML/CSS",
      "Javascript",
      "jQuery",
      "Express",
      "Bootstrap",
      "Chart.js",
      "MongoDB",
      "Mongoose",
      "IndexedDB",
      "Webpack",
    ],
    deploy: ["git", "GitHub", "mLab", "Heroku"],
    client: "personal initiative",
    type: "document",
  },
  
  {
    id: 14,
    title: "Profile Generator using GitHub API",
    thumbImage: generator,
    link: "https://drive.google.com/file/d/1S2UGT1OmhEsEEWPQHaLFJIaxe4lV0tjT/view",
    codeLink: "https://github.com/Ethanlloyd21/Developer-Profile-Generator",
    description:
      "A command-line application that dynamically generates a PDF profile from a GitHub username.",
    technology: ["JavaScript", "HTML", "CSS", "Node.js", "GitHub API"],
    deploy: ["GitHub", "git", "command-line"],
    client: "personal initiative",
    type: "document",
  },
  {
    id: 4,
    title: "Portfolio using React + AWS Amplify",
    thumbImage: website,
    link: "https://lloydmarcelino.com",
    codeLink: "restricted",
    description: "My Professional Portfolio",
    technology: [
      "React",
      "SASS",
      "react-bootstrap",
      "axios",
      "wowjs",
      "react-parallax",
    ],
    deploy: [
      "React",
      "CodeCommit",
      "AWS Amplify",
      "AWS Route 53",
      "AWS AppSync",
      "AWS API Gateway",
    ],
    client: "personal initiative",
    type: "document",
  },
  {
    id: 5,
    title: "React Fitness Tracker",
    thumbImage: resistance,
    link: "not available",
    codeLink: "https://github.com/Ethanlloyd21/Workout-Tracker",
    description:
      "The Workout-Tracker application is a meticulously designed tool engineered to monitor and record the user's fitness activities. By graphically representing the compiled data, it facilitates comprehensive understanding of the user's exercise regimen. This sophisticated software solution was developed employing MongoDB for its database structure, capitalizing on the robust capabilities of the Mongoose schema for data modeling. The routing functionality is smoothly managed via Express, ensuring efficient data flow and interaction. The front-end construction of the application is accomplished using HTML, CSS, JavaScript, and jQuery, ensuring a user-friendly interface and a seamless user experience.",
    technology: [
      "HTML/CSS",
      "Bootstrap",
      "Javascript/jQuery",
      "Chart.js",
      "Express",
      "Node.js",
      "MongoDB",
      "Mongoose",
      "Webpack",
    ],
    deploy: ["git", "GitHub", "mLab", "Heroku"],
    client: "personal initiative",
    type: "document",
  },
  {
    id: 6,
    title: "React Google Book Search",
    thumbImage: google,
    link: "not available",
    codeLink: "https://github.com/Ethanlloyd21/Google-Book-Search",
    description:
      "The Google Books Search application, built on the robust React platform, necessitates the development of purpose-built React components, the employment of helper and utility functions, and the execution of React lifecycle methods to efficiently query and display books in response to user searches. The technological fusion of Node.js, Express, and MongoDB ensures a robust backend that allows users to save books of interest for later review or purchase, thereby enhancing user engagement and interaction. This strategically designed application integrates advanced functionalities to deliver a seamless and intuitive book search experience.",
    technology: ["React", "Node.js", "Express", "MongoDB", "Google API"],
    deploy: ["GitHub", "Heroku"],
    client: "personal initiative",
    type: "document",
  },
  {
    id: 7,
    title: "Trivia QUIZ challenge",
    thumbImage: quiz,
    link: "https://ethanlloyd21.github.io/Code-Quiz/",
    codeLink: "https://github.com/Ethanlloyd21/Code-Quiz",
    description:
      "In this project, the challenge is to build a trivia quiz with multiple-choice questions.",
    technology: ["HTML/CSS", "Javascript", "jQuery"],
    deploy: ["git", "GitHub"],
    client: "personal initiative",
    type: "document",
  },
  {
    id: 9,
    title: "Weather Dashboard",
    thumbImage: weather2,
    link: "https://ethanlloyd21.github.io/Weather-Dashboard/",
    codeLink: "https://github.com/Ethanlloyd21/Weather-Dashboard",
    description:
      "This application harnesses the capabilities of the OpenWeather API to procure comprehensive weather data for cities across the globe. Utilizing AJAX, it effectively interfaces with the API to fetch data in the JSON format, ensuring efficient data extraction and processing. The application operates within the web browser, featuring dynamic HTML and CSS updates that are deftly managed by jQuery. This blend of technologies provides an interactive and real-time user experience, adeptly displaying relevant weather information.",
    technology: ["HTML/CSS", "Bootstrap", "Javascript/Jquery", "API"],
    deploy: ["git", "GitHub"],
    client: "personal initiative",
    type: "document",
  },
  {
    id: 10,
    title: "Password Generator",
    thumbImage: gen,
    link: "https://ethanlloyd21.github.io/Password-Generator/",
    codeLink: "https://github.com/Ethanlloyd21/Password-Generator",
    description:
      "This application has been designed to produce a random password according to parameters chosen by the user. The operation of this application occurs within the browser environment, characterized by the dynamic modification of HTML and CSS elements, which are seamlessly managed through the underlying JavaScript code. This combination of technologies ensures a real-time, responsive user experience tailored to individual security requirements.",
    technology: ["JavaScrip", "HTML", "CSS"],
    deploy: ["GitHub", "git"],
    client: "personal initiative",
    type: "document",
  },
  {
    id: 11,
    title: "Restaurant Reservation",
    thumbImage: restaurant,
    link: "not available",
    codeLink: "https://github.com/Ethanlloyd21/Restaurant-Reservation",
    description:
      "This application, fundamentally developed utilizing Node.js and Express along with jQuery, serves as a practical illustration of these technologies' interoperability. The primary objective of this application is to facilitate reservation scheduling, thereby enhancing operational efficiency for restaurant businesses. With a constraint of five available tables, the application efficiently manages reservation requests. The initial five requests secure table reservations, while any subsequent requests are systematically assigned to a waiting list. This demonstrates a rational approach to managing reservation overflow and maintaining customer satisfaction.",
    technology: ["JavaScript", "jQuery", "Node.js", "Express"],
    deploy: ["GitHub", "git"],
    client: "personal initiative",
    type: "document",
  },
  {
    id: 15,
    title: "Login System using Node.js",
    thumbImage: login,
    link: "https://drive.google.com/file/d/1yJHvVf1gL9gX1j8Ito8YC36e7MRB58zN/view",
    codeLink: "https://github.com/Ethanlloyd21/Login-Register-Validation",
    description:
      "This application is a straightforward yet robust tool designed to facilitate user registration and authentication. It enables the creation and validation of usernames and passwords, ensuring stringent user verification. One of the key features of this application is its ability to maintain user sessions. This ensures that in the event of an unintentional browser closure, the user will not be required to reauthenticate, enhancing user experience and interaction continuity. Importantly, all passwords are securely encrypted within the database as a critical measure to safeguard user data, underscoring our commitment to privacy and security.",
    technology: ["JavaScript", "Pug", "Node.js", "Express", "bcrypt", "MySQL"],
    deploy: ["GitHub", "git"],
    client: "personal initiative",
    type: "document",
  },
 
  {
    id: 3,
    title: "React Sneaker Inventory",
    thumbImage: dashboard,
    link: "not available",
    codeLink: "https://github.com/Ethanlloyd21/project-2",
    description:
      "The JLR Shoes Customer Relationship Management (CRM) system serves as a central repository for the JLR Shoe company, meticulously managing its inventory, supplier, and vendor information. The strategic integration of this CRM provides the company with a competitive edge by consolidating crucial company data into a single, user-friendly platform, ensuring accuracy and accessibility. This sophisticated tool was developed using a robust technology stack, including React for its dynamic interface, Sequelize for object-relational mapping, and MySQL for reliable database management, offering a seamless and efficient operational experience.",
    technology: ["React", "Sequalize", "Express", "Chart.js", "MySQL"],
    deploy: ["git", "GitHub", "JawsDB", "Heroku"],
    client: "personal initiative",
    type: "document",
  },
  {
    id: 17,
    title: "PayPal Payment System using React",
    thumbImage: paypal,
    link: "https://d1v6b7q0w0ae75.cloudfront.net/paypal.webm",
    codeLink: "https://github.com/Ethanlloyd21/React_PayPal_payment",
    description:
      "The primary objective of this application is to establish a reliable platform that enables seamless financial transactions, specifically designed to facilitate customer payments. This application is particularly suited for e-commerce websites where a secure, trustworthy payment gateway is paramount. To achieve this, the application capitalizes on the synergistic blend of React's interactive UI capabilities and the robust, globally accepted PayPal Checkout system. This amalgamation of technologies presents an optimal solution for secure and efficient payment processing in the digital commerce landscape.",
    technology: ["JavaScrip", "React", "PayPal API"],
    deploy: ["GitHub", "git"],
    client: "personal initiative",
    type: "document",
  },
  {
    id: 16,
    title: "Login Authentication using OKTA",
    thumbImage: auth,
    link: "https://drive.google.com/file/d/16a7xBDngn1zJm_vv_ZVdRjvJUYIy957t/view",
    codeLink:
      "https://github.com/Ethanlloyd21/Username-Password-Authentication-React",
    description:
      " This is a simple application of a username and password authentication using React with OKLA integration. A sign-in experience is one of the most important user experiences any app developer needs to consider. To provide a seamless and attractive, yet secure authentication experience is not a trivial task. Typically, the sign-in logic accompanies other features such as password reset and registration. More importantly, enhanced security in the form of strong and adaptive authentication during the sign-in process is often critical to many implementations.",
    technology: ["React", "CSS", "OKTA"],
    deploy: ["GitHub", "git"],
    client: "personal initiative",
    type: "document",
  },
  {
    id: 1,
    title: "Donationally using React",
    thumbImage: Original,
    link: "http://donationally.com",
    codeLink: "https://github.com/Ethanlloyd21/Charity",
    description:
      "Donationally is an intuitive charitable search platform, designed to empower users by providing timely information on global events and numerous opportunities for philanthropy. Our mandate revolves around facilitating transformative impact through each individual donation. Our vision is anchored in the belief of fostering a world where every person is afforded the opportunity to enhance their quality of life. We remain steadfast in our commitment to bridging gaps and fostering inclusivity, one donation at a time.",
    technology: [
      "React",
      "React Hooks",
      "React-Bootstrap",
      "Ant Design",
      "Formik",
      "Yup",
      "axios",
      "Google API",
      "Express",
      "MongoDB",
      "Mongoose",
      "Node.js",
    ],
    deploy: ["git", "GitHub", "mLab", "Heroku"],
    client: "personal initiative",
    type: "document",
  },
  {
    id: 13,
    title: "Employee Template Engine",
    thumbImage: employee,
    link: "https://drive.google.com/file/d/1yO4M8pAAFzpijVdGRg2FCFMybQKnfhEi/view",
    codeLink:
      "https://github.com/Ethanlloyd21/Template-Engine-Employee-Summary",
    description:
      "This project introduces a command-line application specifically designed for the generation of Software Engineering teams. The application initiates by requesting user input concerning the team manager, followed by detailed information regarding team members. Users are given the flexibility to add any number of members to their teams, comprising a blend of engineers and interns. Upon completion of the team creation process, the application ingeniously generates an HTML file, resulting in an aesthetically pleasing and structured team roster derived from user-provided data. To validate the functionality and reliability of the application, 'jest', a comprehensive JavaScript testing framework, is utilized, ensuring all unit tests are successfully passed and application integrity is maintained.",
    technology: ["JavaScript", "HTML", "CSS", "jest"],
    deploy: ["GitHub", "git"],
    client: "personal initiative",
    type: "document",
  }
];

export const projectsData = [
  {
    id: 20,
    title: "OLIVIA",
    thumbImage: olivia,
    link: "restricted",
    codeLink: "restricted",
    description: "OLIVIA, a bespoke React application, has been meticulously engineered to cater to the distinct data management requirements of Global Security Operations. This robust system is equipped with a diverse array of features including the capability to manage distribution lists, an extensive database of corporate building information, comprehensive contact directories, an advanced disaster risk intelligence platform, and a versatile language translation tool. This tailored suite of functionalities makes OLIVIA an indispensable asset in the enterprise security domain. Due to the inclusion of sensitive and proprietary content, Olivia is bound by a non-disclosure agreement, thus ensuring the confidentiality and security of the included data. To uphold stringent security standards, Olivia's source code remains inaccessible for public viewing.",
    technology: ["React", "Ant Design", "React Bootstrap", "Node.js", "AWS Amplify", "Cognito", "API Gateway", "Lambda", "Cloud Front", "Cloud Watch", "Athena", "Amazon Translate", "DynamoDB", "S3", "Leaflet map", "USGS API", "Disaster Aware API", "Cloud Formation"],
    deploy: ["AWS Amplify CLI", "Cloud Formation", "serverless"],
    client: "ACS - Seattle, WA.",
    type: "document",
  },
  {
    id: 8,
    title: "ROXANNE",
    thumbImage: roxanne,
    link: "restricted",
    codeLink: "restricted",
    description:
    "Roxanne represents a sophisticated JAVA application, developed exclusively for a GSOC team. This cutting-edge software provides a user-friendly interface for accessing critical data such as contacts and emails, managing panels, and creating or modifying issue tickets. Additionally, it offers functionality to observe various procedures. Roxanne significantly augments team productivity by providing a streamlined approach to data management. Due to the inclusion of sensitive and proprietary content, Roxanne is bound by a non-disclosure agreement, thus ensuring the confidentiality and security of the included data. To uphold stringent security standards, Roxanne's source code remains inaccessible for public viewing.",
    technology: ["Java", "Java GUI", "Java Window Builder"],
    deploy: ["Java GUI"],
    client: "ACS - Seatlle, WA.",
    type: "document",
  },
  {
    id: 21,
    title: "Real Estate Website",
    thumbImage: estate,
    link: "https://presalecondobc.com",
    codeLink: "restricted",
    description:
      "PresaleCondoBC.com is a premier online portal I designed to spotlight the most anticipated presale condominiums in British Columbia, particularly within the energetic cityscape of Vancouver. Built from the ground up with prospective condo owners in mind, the website offers rich details on forthcoming condo projects, serving as a pivotal resource for those eager to make informed decisions. I proudly created this project for a valued customer in Vancouver, BC, Canada, ensuring that the platform not only resonates with the region's unique real estate pulse but also stands as a testament to our commitment to excellence and localized insight. Note: Project in progress... Code will be available soon.",
    technology: ["React", "Typescript", "Redux Toolkit", "Vite"],
    deploy: ["AWS Amplify", "AWS AppSync", "AWS DynamoDB", "AWS Cognito"],
    client: "Pre Sale Condo - Vancouver B.C. Canada",
    type: "document",
  }
];
