import React from "react";
import { Link } from "react-scroll";
import { Parallax } from "react-parallax";
import { scrollDuration } from "../config/commonConfig";

const CallToAction = () => {
  //open a new window for the blog
  const openInNewTab = (url) => {
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };

  return (
    <section>
      <div className="hero-wrap">
        <div className="hero-mask opacity-8 bg-dark" />
        <Parallax bgImage="images/coding.jpg" bgImageAlt="Intro" strength={200}>
          <div className="hero-content section">
            <div className="container text-center py-5 wow fadeInUp">
              <h2 className="text-10 fw-600 text-white mb-5">
                Do you want to learn how to harness the power of AWS in building serverless
                applications?
              </h2>
              <button
                className="btn btn-primary rounded-0 smooth-scroll wow rubberBand"
                data-wow-delay="1s"
                smooth="easeInOutQuint"
                duration={scrollDuration}
                // to="contact"
                onClick={() => openInNewTab("https://serverless-samurai.com")}
              >
                Join me @ serverles-samurai.com!
              </button>{" "}
            </div>
          </div>
        </Parallax>
      </div>
    </section>
  );
};

export default CallToAction;
