import React, { useRef, useState } from "react";
// import emailjs from "@emailjs/browser";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Tooltip } from "./Tooltip";

const Contact = () => {
  const form = useRef();
  const [sendingMail, setSendingMail] = useState(false);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  //honeycomb

  const [message2, setMessage2] = useState(false);

  // const sendEmail = (e) => {
  //   e.preventDefault();
  //   setSendingMail(true);
  //   emailjs
  //     .sendForm(
  //       "service_ugrf7rs",
  //       "template_sp0z9tr",
  //       form.current,
  //       "cQd_8e2aTx7swaRCr"
  //     )
  //     .then(
  //       (result) => {
  //         document.getElementById("contact-form").reset();
  //         toast.success("Message sent successfully!", {
  //           position: "top-right",
  //           autoClose: 5000,
  //           hideProgressBar: false,
  //           closeOnClick: true,
  //           pauseOnHover: true,
  //           draggable: true,
  //           progress: undefined,
  //           theme: "light",
  //         });
  //         console.log(result.text);
  //         setSendingMail(false);
  //       },
  //       (error) => {
  //         toast.error("Something went wrong!", {
  //           position: "top-right",
  //           autoClose: 5000,
  //           hideProgressBar: false,
  //           closeOnClick: true,
  //           pauseOnHover: true,
  //           draggable: true,
  //           progress: undefined,
  //           theme: "light",
  //         });
  //         console.log(error.text);
  //         setSendingMail(false);
  //       }
  //     );
  // };

  const handleSubmit = (e) => {
    e.preventDefault();
    setSendingMail(true);

    try {
      fetch(
        "https://nqdjjdamdf.execute-api.us-east-1.amazonaws.com/sendEmail",
        {
          mode: "no-cors",
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            senderName: "serverless.samurai@gmail.com",
            senderEmail: "lloydmarcelino@gmail.com",
            message: `<div><p><b>Name:</b> ${name}</p>
            <p><b>Email:</b> ${email}</p>
            <p><b>Message:</b> ${message}</p></div>`,
          }),
        }
      );

      document.getElementById("contact-form").reset();
      toast.success("Message sent successfully!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      setSendingMail(false);
    } catch (error) {
      toast.error("Something went wrong!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });

      setSendingMail(false);
    }
  };

  return (
    <section id="contact" className="section bg-primary">
      <div className="container">
        <div className="row">
          <div className="col-lg-5 text-center text-lg-start wow fadeInUp">
            <h2 className="text-10 fw-600 mb-5">Let's get in touch</h2>
            <p className="text-5 mb-5">
              I have a keen interest in discussing new projects and design
              challenges. To ensure that our initial meeting is productive, I
              kindly request that you provide as much information as possible
              beforehand. This will enable us to make the most of our first
              catch-up
            </p>
            <h3 className="text-5 fw-600">Location:</h3>
            <address className="text-4">Dallas, Texas</address>
            <h3 className="text-5 fw-600">Email:</h3>
            <p className="text-4">lloydmarcelino@gmail.com</p>
            <ul className="social-icons social-icons-lg justify-content-center justify-content-lg-start mt-5">
              <li className="social-icons-facebook">
                <Tooltip text="Facebook" placement="top">
                  <a
                    href="http://www.facebook.com/harnishdesign/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fab fa-facebook" />
                  </a>
                </Tooltip>
              </li>
              <li className="social-icons-linkedin">
                <Tooltip text="LinkedIn" placement="top">
                  <a
                    href="https://www.linkedin.com/in/lloydmarcelino619/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fab fa-linkedin-in" />
                  </a>
                </Tooltip>
              </li>
              <li className="social-icons-github">
                <Tooltip text="Github" placement="top">
                  <a
                    href="https://github.com/Ethanlloyd21"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fab fa-github" />
                  </a>
                </Tooltip>
              </li>
            </ul>
          </div>
          <div
            className="col-lg-6 ms-auto mt-5 mt-lg-0 wow fadeInUp"
            data-wow-delay="0.3s"
          >
            <h2 className="text-10 fw-600 text-center text-lg-start mb-5">
              Send me a message
            </h2>
            {/* Contact Form */}
            <form
              id="contact-form"
              className="form-border"
              method="post"
              ref={form}
              onSubmit={handleSubmit}
            >
              <div className="row g-4">
                <div className="col-12">
                  <label className="form-label" htmlFor="name">
                    What is Your Name:
                  </label>
                  <input
                    id="name"
                    name="user_name"
                    type="text"
                    className="form-control py-1"
                    required
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </div>
                <div className="col-12">
                  <label className="form-label" htmlFor="email">
                    Your Email Address:
                  </label>
                  <input
                    id="email"
                    name="reply_to"
                    type="email"
                    className="form-control py-1"
                    required
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
                <div className="col-12">
                  <label className="form-label" htmlFor="form-message">
                    How can I Help you?:
                  </label>
                  <textarea
                    id="form-message"
                    name="message"
                    className="form-control py-1"
                    rows={4}
                    required
                    defaultValue={""}
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                  />
                </div>
                <div className="col-12" style={{ zIndex: -1 }}>
                  <label className="form-label" htmlFor="form-message2">
                    How can I Help you2?:
                  </label>
                  <input
                    id="email2"
                    name="reply_to"
                    type="email"
                    className="form-control py-1"
                    onChange={() => setMessage2(true)}
                  />
                </div>
                <div className="col-12 text-center text-lg-start">
                  {message2 ? (
                    <button
                      id="submit-btn"
                      className="btn btn-dark rounded-0"
                      disabled
                    >
                      <>
                        Send{" "}
                        <span className="ms-3">
                          <i className="fas fa-arrow-right" />
                        </span>
                      </>
                    </button>
                  ) : (
                    <button
                      id="submit-btn"
                      className="btn btn-dark rounded-0"
                      type="submit"
                    >
                      {sendingMail ? (
                        <>
                          <span
                            role="status"
                            aria-hidden="true"
                            className="spinner-border spinner-border-sm align-self-center me-2"
                          ></span>
                          Sending......
                        </>
                      ) : (
                        <>
                          Send{" "}
                          <span className="ms-3">
                            <i className="fas fa-arrow-right" />
                          </span>
                        </>
                      )}
                    </button>
                  )}
                </div>

                <ToastContainer />
              </div>
            </form>
            {/* Contact Form end */}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contact;
