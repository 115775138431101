import React from "react";

import Slider from "react-slick";

const Testimonials = () => {
  const reviews = [
    {
      name: "Spencer Shadley",
      position: "Principal Software Engineer at Tableau",
      src: "images/testimonial/spencermini.jpg",
      desc: "“Lloyd’s devotion to perfection is astounding. He is dedicated to making the highest quality product because he so deeply cares about everything he works on. Lloyd’s dedication was so extreme I actually had to talk to him several times to try and get him to focus on things like sleep! Lloyd will devote himself to ensure he is successful at anything he commits to”",
    },
    {
      name: "Owen Chantala",
      position: "Full-Stack Developer at Pacific Science & Engineering",
      src: "images/testimonial/owen2.jpg",
      desc: " “Had the pleasure of working with Lloyd. Lloyd was not only able to provide many responsive design components but created logos and various information for our application. His hard work paid off during the day of our presentation.”",
    },
    {
      name: "Dane Shrewsbury",
      position: "Freelance Software Developer",
      src: "images/testimonial/dane2.jpg",
      desc: "“When I think of Lloyd Marcelino, the one word that comes to mind is diligence. His desire to learn and improve his skills set him apart. These traits will undoubtedly make him a valuable asset for any programming position and this comes with my genuine recommendation.”",
    },
    {
      name: "Ari Horowitz",
      position:
        "Associate Technical Project Manager at Launch Consulting Group",
      src: "images/testimonial/ari2.jpg",
      desc: "“Lloyd showcased his strong leadership abilities over the course of the project by scheduling mindful meetings, asking clarifying questions on project initiatives, and delegating tasks effectively. Lloyd's passion, drive, and commitment to improve are qualities that will translate directly to your team. As a team member and leader, Lloyd earns my highest recommendation.”",
    },
    {
      name: "Joseph Kaitschuck",
      position: "Business Process Owner at USAA",
      src: "images/testimonial/joseph2.jpg",
      desc: "“It has been my great pleasure to work with Lloyd in a corporate Global Security Operations Center environment. I have found Lloyd to be very intelligent and technically proficient in the tasks assigned to him. Lloyd is a good communicator and that made it easy for me to know how best to provide appropriate supervision and guide him to reaching his full potential. I would recommend Lloyd for any position that would utilize Lloyd's abilities to effectively problem solve and manage intricate projects.”",
    },
  ];

  const SlickArrowLeft = ({ currentSlide, slideCount, ...props }) => (
    <button
      {...props}
      className={
        "slick-prev slick-arrow" + (currentSlide === 0 ? " slick-disabled" : "")
      }
      aria-hidden="true"
      aria-disabled={currentSlide === 0 ? true : false}
      type="button"
    >
      <i className="fa fa-chevron-left"></i>
    </button>
  );
  const SlickArrowRight = ({ currentSlide, slideCount, ...props }) => (
    <button
      {...props}
      className={
        "slick-next slick-arrow" +
        (currentSlide === slideCount - 1 ? " slick-disabled" : "")
      }
      aria-hidden="true"
      aria-disabled={currentSlide === slideCount - 1 ? true : false}
      type="button"
    >
      <i className="fa fa-chevron-right"></i>
    </button>
  );

  var settings = {
    dots: true,
    arrows: true,
    prevArrow: <SlickArrowLeft />,
    nextArrow: <SlickArrowRight />,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4500,
  };

  return (
    <section id="testimonial" className="section bg-secondary">
      <div className="container">
        {/* Heading */}
        <p className=" text-center mb-2 wow fadeIn">
          <span className="bg-primary text-dark px-2">Testimonials</span>
        </p>
        <h2 className="text-10 fw-600 text-white text-center mb-5 wow fadeIn">
          References from my colleagues
        </h2>
        {/* Heading end*/}
        <div className="row">
          <div className="col-lg-9 mx-auto wow fadeInUp">
            <Slider {...settings}>
              {reviews.length > 0 &&
                reviews.map((review, index) => (
                  <div className="item text-center px-5" key={index}>
                    {" "}
                    <span className="text-9 text-primary">
                      <i className="fa fa-quote-start" />
                    </span>
                    <p className="text-5 text-white">{review.desc}</p>
                    <img
                      className="img-fluid d-inline-block w-auto rounded-circle shadow"
                      src={review.src}
                      alt={review.name}
                    />{" "}
                    <strong className="d-block text-3 fw-600 text-white">
                      {review.name}
                    </strong>{" "}
                    <span className="text-light">{review.position}</span>{" "}
                  </div>
                ))}
            </Slider>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Testimonials;
